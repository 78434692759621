/**
 * Spinner
 */

export function spinner () {
  // Show
  var create = function () {
    var elem = document.createElement('div')
    elem.setAttribute('id', 'spinner')
    elem.innerHTML =
        '<div class="sk-chase">' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
        '</div>';

    document.body.appendChild(elem)
    show(elem)
  };

  var show = function (elem) {
    // elem.className += 'show'
    var el = document.getElementById('spinner')
    el.classList.add('show')
  };

  // Hide
  var hide = function () {
    var el = document.getElementById('spinner')
    // el.parentNode.removeChild(el)
    el.classList.remove('show')
  };

  return {
    create: create,
    show: show,
    hide: hide
  }
}

export default spinner
