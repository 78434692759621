/**
 * Navbar
 */

export function navbar () {
  // Debounce function which waits 10ms before firing. We use it so we don't fire events like crazy during long scroll sessions

  function debounce (func, wait = 10, immediate = true) {
    let timeout
    return function () {
      let context = this
      let args = arguments
      let later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      let callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  $('.navbar').addClass('tween');

  // Show menu overlay when click the navbar toggler
  const navbarToggler = document.querySelector('.js-navbar-toggler');
  var windowScrollTop = 0;
  // menu button
  if (navbarToggler) {
    navbarToggler.addEventListener('click', function (event) {
      event.preventDefault();      
      if($('body').hasClass('navbar--opened')){
        $('body').removeClass('navbar--opened');
        resetPosition (windowScrollTop)
      } else if ($('body').hasClass('navbar--search-opened')) {
        $('body').removeClass('navbar--search-opened');
        resetPosition (windowScrollTop)
      } else {
        windowScrollTop = $(window).scrollTop();
        $('body').removeClass('navbar--search-opened');
        $('body').addClass('navbar--opened');
        manteinPosition (windowScrollTop);
      }
    });
  }

  function manteinPosition (windowScrollTop) {
    $('.wrapper').css({
      'overflow': 'hidden',
      'height': $(window).height(),
      'width': $(window).width(),
      'position': 'fixed'
    });

    $('.main').css({
      'position': 'relative',
      'top': -windowScrollTop
    });

    $(window).scrollTop(0);
  }

  function resetPosition (windowScrollTop) {
    $('.wrapper').removeAttr('style');
    $('.main').removeAttr('style');
    window.scrollBy(0, windowScrollTop);
  }

  // Add class to navbar after certain amount of pixels are scrolled
  const navbarElement = document.querySelector('.js-navbar')

  // Navbar slides out of view when scrolling down and slides back in when scrolling up
  // Based on https://dev.to/changoman/showhide-element-on-scroll-w-vanilla-js-3odm

  if (navbarElement) {
    if (navbarElement.classList.contains('navbar--fixed-showhide') && !$('body').hasClass('navbar--opened')) {
      const navbarShowHidetPixels = navbarElement.clientHeight;
      let scrollPos = 0;

      function navbarShowHide () {
        var scrolled = document.scrollingElement.scrollTop;
        var position = navbarElement.offsetTop;
        if (scrolled > position + navbarShowHidetPixels) {
          let windowY = window.scrollY
          if (windowY < scrollPos) {
            // Scrolling UP
            navbarElement.classList.add('on-stage')
            navbarElement.classList.remove('out-stage')
          } else {
            // Scrolling DOWN
            navbarElement.classList.add('out-stage')
            navbarElement.classList.remove('on-stage')
          }
          scrollPos = windowY
        } else {
          navbarElement.classList.remove('on-stage')
        }
      }

      window.addEventListener('scroll', debounce(navbarShowHide))
    }
  }
}

export default navbar
