/**
 * Search overlay
 */

export function searchOverlay () {
  const navbarElement = document.querySelector('.js-navbar');
  const navbarSearchToggler = $('.js-navbar-seach-toggler');
  var windowScrollTop = 0;  
  // search button
  if (navbarSearchToggler) {
    navbarSearchToggler.each(function(){
      $(this).on('click', function (event) {
        event.preventDefault();      
        if($('body').hasClass('navbar--search-opened')){
          $('body').removeClass('navbar--search-opened');
          window.scrollBy(0, windowScrollTop);
        } else {
          $('body').removeClass('navbar--opened');
          navbarElement.classList.remove('out-stage');
          windowScrollTop = $(window).scrollTop();
          $('body').addClass('navbar--search-opened');
          manteinPosition (windowScrollTop);
        }
      });
    })
  }

  function manteinPosition (windowScrollTop) {
    $('.wrapper').css({
      'overflow': 'hidden',
      'height': $(window).height(),
      'width': $(window).width(),
      'position': 'fixed'
    });

    $('.main').css({
      'position': 'relative',
      'top': -windowScrollTop
    });

    $(window).scrollTop(0);
  }

  function resetPosition (windowScrollTop) {
    $('.wrapper').removeAttr('style');
    $('.main').removeAttr('style');
    window.scrollBy(0, windowScrollTop);
  }


}

export default searchOverlay
