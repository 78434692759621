/**
 * Thom Observe Animation
 */

 import {gsap} from "gsap";
 import { ScrollTrigger } from "gsap/ScrollTrigger";

 gsap.registerPlugin(ScrollTrigger);

import LazyLoad from "vanilla-lazyload";

var toaLazyLoad;
var jQBridget = require("jquery-bridget");
var Packery = require("packery");
$.bridget("packery", Packery, $);

 export function toa () {

    $(window).on("resizeend", 250, function () {
        toaResize()
    });

    toaResize();
    
}

export function toaInit(item){
    
    toaLazyLoad = null;
    let observerElements = null;
    observerElements = gsap.utils.toArray('.js-toa');

    gsap.defaults({ease: "power2"});

    if(item) {
        observerElements = gsap.utils.toArray(item);
    }
    observerElements.forEach(section => {
        ScrollTrigger.create({
            trigger: section,
            start: "-50% bottom",
            once: true,
            onEnter: () => {
                if($(section).find('.js-toa-lazy').length > 0){
                    var images = gsap.utils.toArray($(section).find('.js-toa-lazy'));
                    images.forEach((image, index, array) => {
                        if(!$(image).hasClass('loaded')){
                            let newSRC = image.dataset.src;
                            let newImage = document.createElement("img");
                            newImage.src = newSRC;
                            newImage.onload = () => {
                                newImage.onload = null; // avoid recursion
                                newImage.src = image.dataset.src; // swap the src
                                image.src = newSRC;
                                // place the low-res version on TOP and then fade it out.
                                gsap.set(newImage, {
                                    position: "absolute", 
                                    top: image.offsetTop, 
                                    left: image.offsetLeft, 
                                    width: image.offsetWidth, 
                                    height: image.offsetHeight
                                });
                                image.parentNode.appendChild(newImage);
                                gsap.to(newImage, {
                                    opacity: 0, 
                                    onComplete: () => {
                                        newImage.parentNode.removeChild(newImage);
                                        image.classList.add('loaded');
                                        toaUpdate();
                                        if (index === array.length - 1) {
                                            toaCallback(section);
                                        }
                                    }
                                });
                            }
                        } else {
                            toaCallback(section);
                        }
                    })
                } else {
                    toaCallback(section);
                }
            }
        })
    })

}

export function toaCallback(section) {
    if($(section).hasClass("js-toa-grid")){
        var pckry = $(".js-toa-grid").packery({
            // options
            itemSelector: ".grid-item",
            percentPosition: true,
        });
    }

    if(section.hasAttribute("data-toa-anim")){
        var attr = section.getAttribute("data-toa-anim");
        toaAnimationReveal(attr, section);
    } else if(section.hasAttribute("data-toa-class")){
        gsap.to(section, {
            className:"+=" + section.classList + ' ' + section.attributes["data-toa-class"].value,
            stagger: 0.35,
            scrollTrigger: {
                // trigger: el,
                start: "top 100%"
            },
            onComplete: function(){
                if(section.hasAttribute("data-toa-parallax")){
                    toaParallaxMove(section);
                }
            }
        });
    } else if(section.hasAttribute("data-toa-parallax")){
        toaParallaxMove(section);
    }

    if($(section).find('.js-toa-el').length > 0){

        if(!section.hasAttribute("data-toa-anim")){
            gsap.to(section, {
                opacity: 1
            });
        }
        var elements = gsap.utils.toArray($(section).find('.js-toa-el'));
        ScrollTrigger.batch(elements, {
            start: "top 100%",
            once: true,
            toggleActions: 'play none play none',
            onEnter: batch => {
                batch.forEach(element => {
                    if(element.hasAttribute("data-toa-anim")){
                        var attr = element.getAttribute("data-toa-anim");
                        toaAnimationReveal(attr, element);
                    } else if(element.hasAttribute("data-toa-class")){
                        gsap.to(element, {
                            className:"+=" + element.classList + ' ' + element.attributes["data-toa-class"].value,
                            stagger: 0.35,
                            onComplete: function(){
                                if(element.hasAttribute("data-toa-parallax")){
                                    toaParallaxMove(element);
                                }   

                                if($(element).hasClass('grid-item-el')){
                                    if(pckry !== undefined) {
                                        pckry.packery('layout');
                                    }
                                }
                            }
                        });                    
                    } else if(element.hasAttribute("data-toa-parallax")){
                        toaParallaxMove(element);
                    } 
                });
            }
        });
    }
}

export function toaAnimationReveal(anim, el) {
    if(anim === "slideUp") {
        gsap.set(el, {y: 100});
        gsap.to(el, {
            scrollTrigger: {
                // trigger: el,
                // markers: true,
                start: "top 100%"
            },
            delay: 0.35,
            duration: 1,
            opacity: 1,
            y: 0,
            stagger: 0.35,
            overwrite: true,
        });
    } else if(anim === "fade") {
        gsap.to(el, {
            scrollTrigger: {
                // trigger: el,
                start: "top 100%"
            },
            delay: 0.35,
            duration: 1,
            opacity: 1,
            stagger: 0.35,
            overwrite: true
        });
    }
}

export function toaUpdate() {
    ScrollTrigger.refresh()
}
export function toaDisable(callback) {
    var st = ScrollTrigger.getAll();

    if(st.length > 0){
        st.forEach((stE, index, array) => {
            stE.kill();
            if (index === array.length - 1) {
                if(callback){
                    callback();
                    ScrollTrigger.refresh();
                }
            }
        });
        $('js-toa, .js-toa-el').removeAttr('style');
    }
}

export function toaResize(){
    var st = ScrollTrigger.getAll();
    
    if (matchMedia("screen and (min-width: 920px)").matches) {
        if(st.length === 0){
            toaInit();
        } 
        // else {
        //     toaDisable(toaInit);
        // }
    } else {
        if(st.length > 0){
            toaDisable();
        }

        if ($(".grid").length > 0 && $('.grid').hasClass('js-toa-grid')){
            var pckry = $(".js-toa-grid").packery({
                // options
                itemSelector: ".grid-item",
                percentPosition: true,
            });
        }
        
        toaLazyLoad = new LazyLoad({
            unobserve_entered: true,
            elements_selector: ".js-toa-lazy",
            callback_loaded: function(el){
                if($(el).parent().hasClass('grid-item-el')){                    
                    if(pckry !== undefined) {
                        pckry.packery('layout');
                    }
                }
            }
        });
    }
}

export function toaParallaxMove(div){
    var movement_s = (div.offsetHeight / 2) * div.attributes["data-toa-parallax-s"].value;
    var movement_e = (div.offsetHeight / 2) * div.attributes["data-toa-parallax-e"].value;
    // console.log(div.offsetHeight)   

    gsap.fromTo(div,
        {
            yPercent: (i, target) => movement_s,
            ease: "none"
        },
        {
            yPercent: (i, target) => movement_e,
            // yPercent: -100 * div.attributes["data-toa-parallax"].value,
            scrollTrigger: {
                start: "top 110%",
                trigger: div,
                scrub: true,
                markers: true,
                overwrite: true
                // end: window.innerHeight + section.offsetHeight * 2,
            },
        ease: "slow"
    }
    );
}

export default toa